import tw from 'twin.macro'
import { globalCss } from '../stitches.config'

const stylesBase = {
  // '&.light': { }
  'html': {
    'box-sizing': 'border-box',
    height: '100%',
    margin: 0,
    padding: 0,
    //  Prevent iOS text size adjust after orientation change, without disabling  user zoom.
    '-ms-text-size-adjust': '100%',
    '-webkit-text-size-adjust': '100%',

    /* These are technically the same, but use both */
    'overflow-wrap': 'break-word',
    'word-wrap': 'break-word',

    '-ms-word-break': 'break-all',
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    // 'word-break': 'break-all'
    /* Instead use this non-standard one: */
    'word-break': 'break-word',

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    '-ms-hyphens': 'auto',
    '-moz-hyphens': 'auto',
    '-webkit-hyphens': 'auto',
    'hyphens': 'auto'

  },
  /* default text and background color */
  'body': {
    color: "$text-primary",
    background: "$background-primary",
    'font-family': '"Noto Sans JP",mui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
  },
  //  *: before, *: after {
  "*": {
    'box-sizing': 'border-box',
    margin: 0,
    padding: 0,
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },
  // storybook story body element padding
  ".sb-show-main.sb-main-padded": {
    padding: 0
  },
  // storybook end
  // react-slick
  ".slick-slide": {
    height: 'auto' // ← that must not be ignored
  },
  ".slick-track": {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
  },
  // react-icons
  '.react-icons': {
    verticalAlign: 'middle'
  },
  // react-slick end
  // a tag url wrap

  // a tag url wrap ends

  // twitter timeline
  // TODO: adjust twitter scrollbar
  "iframe.twitter-timeline": {
    "&::-webkit-scrollbar": {
      width: '10px'
    },
    "&::-webkit-scrollbar-track": {
      background: "orange"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "blue",
      "&:hover": {
        background: "red"
      }
    },
    // twitter timeline end
  }
}

// const stylesBase2 = css`
//   /* theme */
//   :root {
//     /* background color*/
//     --bg-primary: #ffffff;
//     --bg-secondary: #f5f5f5;
//     --bg-tertiary: #fafafa;
//     --bg-hamburger: ${theme`colors.gray.900`};
//     --bg-leftLine: ${theme`colors.gray.700`};
//     /* text color */
//     --text-primary: #36313d;
//     --text-secondary: #232129;
//     --text-tertiary: #aab8c6;
//     --text-light: #515353;
//     /* divide color */
//     --divide-primary: ${theme`colors.gray.200`};
//     --divide-secondary: #51494c;
//     --divide-tertiary: ${theme`colors.gray.600`};
//     /* ring color */
//     --ring-primary: ${theme`colors.gray.200`};
//     --ring-secondary: #51494c;
//     --ring-tertiary: ${theme`colors.gray.600`};
//     /* border color */
//     /* --border-primary: #D4D4D4;; */
//     --border-primary: ${theme`colors.gray.200`};
//     --border-secondary: #51494c;
//     --border-tertiary: #ccfbf1;
//   }
//   .light {
//     /* background color*/
//     --bg-primary: #ffffff;
//     --bg-secondary: #f5f5f5;
//     --bg-tertiary: #fafafa;
//     --bg-hamburger: ${theme`colors.gray.900`};
//     --bg-leftLine: ${theme`colors.gray.700`};
//     /* text color */
//     --text-primary: #36313d;
//     --text-secondary: #232129;
//     --text-tertiary: #aab8c6;
//     --text-light: #515353;
//     /* divide color */
//     --divide-primary: ${theme`colors.gray.200`};
//     --divide-secondary: #51494c;
//     --divide-tertiary: ${theme`colors.gray.600`};
//     /* ring color */
//     --ring-primary: ${theme`colors.gray.200`};
//     --ring-secondary: #51494c;
//     --ring-tertiary: ${theme`colors.gray.600`};
//     /* border color */
//     /* --border-primary: #D4D4D4;; */
//     --border-primary: ${theme`colors.gray.200`};
//     --border-secondary: #51494c;
//     --border-tertiary: #ccfbf1;
//   }
//   .dark {
//     --bg-primary: ${theme`colors.black`};
//     --bg-secondary: ${theme`colors.gray.900`};
//     --bg-tertiary: ${theme`colors.gray.800`};
//     --bg-hamburger: ${theme`colors.gray.50`};
//     --bg-leftLine: ${theme`colors.gray.200`};
//     --text-primary: #e7e5e4;
//     /* rgba(231, 229, 228, 0.9); */
//     --text-secondary: #f5f5f4;
//     --text-tertiary: #d4d4d4;
//     --text-light: #d4d4d4;
//     --border-primary: #e7e5e4;
//     --border-secondary: #f5f5f4;
//     --border-tertiary: ${theme`colors.green.700`};
//     -divide-primary: #e7e5e4;
//     -divide-secondary: #f5f5f4;
//     -divide-tertiary: #d4d4d4;
//     --ring-primary: #e7e5e4;
//     --ring-secondary: #f5f5f4;
//     --ring-tertiary: #d4d4d4;
//   }

//   /* for ios devices hiding curved borders*/
//   // .gatsby-image-wrapper {
//   //   position: relative;
//   //   z-index: 0;
//   // }

//   *, *:before, *:after {
//     box-sizing: border-box;
//     margin: 0;
//     padding: 0;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }


//   body {
//     width: 100%;
//     height: 100%;
//     font-family: "Noto Sans JP", mui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//   }

//   // ul {
//     // list-style-type: none;
//   // }

//   a {
//     // text-decoration: none;
//      ${tw`no-underline`}
//     &:link {
//      ${tw`no-underline`}
//       // text-decoration: none;
//     }
//     &:visited {
//      ${tw`no-underline`}
//       // text-decoration: none;
//     }
//     &:hover {
//      ${tw`no-underline`}
//       // text-decoration: none;
//     }
//   }

// `

export default stylesBase
