import React from "react"
import tw, { theme, globalStyles } from 'twin.macro'
import { globalCss } from '../stitches.config'

import CustomStyles from "./CustomStyles"

const GlobalStyles = () => {
  globalCss(CustomStyles)()
  globalCss(globalStyles as Record<any, any>)()
}

export default GlobalStyles