module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"木下良税理士事務所","short_name":"木下良税理士事務所","start_url":"/","theme_color":"#F5F5F5","background_color":"#FFFFFF","display":"standalone","icon":"src/images/maskable_icon.png","theme_color_in_head":false,"icon_options":{"purpose":"any maskable"},"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cb26c27747d63c4792515c40dff4ddba"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://kinoshitax.jellybean.jp/cms/graphql","verbose":true,"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"schema":{"timeout":50000,"requestConcurrency":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"previewRequestConcurrency":5},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"木下良税理士事務所","titleTemplate":"%s | 木下良税理士事務所","language":"ja","noindex":false,"nofollow":false,"openGraph":{"url":"https://www.kinoshitax.com","type":"website","title":"木下良税理士事務所","description":"当事務所は、平成21年に開業して10年余、多くの方々の税務申告をさせていただいております。中小企業の営業と生活をまもるために、憲法にもとづく納税者の権利を擁護する立場で税務と会計の業務をおこなうよう努めております。","locale":"ja","site_name":"木下良税理士事務所"},"keywords":["税理士","税務","会計","税金"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
