export const COLOR_MODE_KEY = "color-theme"

export const Size = {
  xs: "320",
  mobile: "481",
  tablet: "768",
  laptop: "1024",
  desktop: "2560",
}

export const HEADER_HEIGHT = { default: "48px", mobile: "80px" }

export const THEMETOGGLER_HEIGHT = { default: "48px", mobile: "48px" }
