import {
  createStitches,
  CSS as StitchesCSS,
  keyframes as StitchesKeyframes,
} from "@stitches/react"
import { Size } from "@/constants"
export type { CSS } from "@stitches/react/types/css-util"

export const stitches = createStitches({
  prefix: "",
  theme: {
    colors: {
      gray900: "#0f172a",
      gray800: "#1e293b",
      gray700: "#334155",
      gray600: "#475569",
      gray500: "#64748b",
      gray400: "#94a3b8",
      gray300: "#cbd5e1",
      gray200: "#e2e8f0",
      gray100: "#f1f5f9",
      gray50: "#f8fafc",

      blue200: "#bfdbfe",
      blue300: "#93c5fd",
      blue400: "#60a5fa",
      blue500: "#3b82f6",
      blue600: "#2563eb",
      blue700: "#1d4ed8",

      red0: "red",
      yellow0: "yellow",

      // Alias
      "text-primary": "$gray900",
      "text-secondary": "$gray800",
      "text-tertiary": "$gray700",
      "background-primary": "$gray50",
      "background-secondary": "$gray100",
      "background-tertiary": "$gray200",
      "border-primary": "$gray900",
      "border-seconday": "$gray800",
      "border-tertiary": "$gray700",
      "link-blue-light": "$blue600",
      "link-blue-dark": "$blue700",
      sample: "$red0",
      link: "$blue500",
      "bg-image": 0.3,
    },
  },
  utils: {},
  media: {
    dark: "(prefers-color-scheme: dark)",
    mobile: `(min-width: ${Size.mobile}px)`,
    tablet: `(min-width: ${Size.tablet}px)`,
    laptop: `(min-width: ${Size.laptop}px)`,
    desktop: `(min-width: ${Size.desktop}px)`,
  },
})

export const darkTheme = stitches.createTheme({
  colors: {
    "text-primary": "$gray100",
    "text-secondary": "$gray200",
    "text-tertiary": "$gray300",
    "background-primary": "$gray900",
    "background-secondary": "$gray800",
    "background-tertiary": "$gray700",
    "border-primary": "$gray300",
    "border-seconday": "$gray200",
    "border-tertiary": "$gray100",
    "link-blue-light": "$blue400",
    "link-blue-dark": "$blue500",
    link: "$blue500",
    sample: "$yellow0",
    "bg-image": 0.7,
  },
})

export const { css, styled, globalCss, theme, keyframes, getCssText } = stitches
