import React from 'react';
import { COLOR_MODE_KEY } from '@/constants';
import Terser from "terser"
import { darkTheme, getCssText } from "../../stitches.config"

export type ColorMode = 'light' | 'dark';

export type ThemeContextValue = {
  colorMode: ColorMode;
  setColorMode: (newValue: ColorMode) => void;
};

const themes = {
  light: 'light',
  dark: darkTheme.className,
};

function setColorsByTheme() {
  const colorModeKey = "🔑"
  const darkThemeClassName = "🌙"

  const mql = window.matchMedia("(prefers-color-scheme: dark)")
  const prefersDarkFromMQ = mql.matches
  const persistedPreference = localStorage.getItem(colorModeKey)

  let colorMode = "light"

  const hasUsedToggle = typeof persistedPreference === "string"

  if (hasUsedToggle) {
    colorMode = persistedPreference
  } else {
    colorMode = prefersDarkFromMQ ? "dark" : "light"
  }

  let root = document.documentElement
  if (colorMode === "dark") {
    root.classList.add(darkThemeClassName)
  }
}

const MagicScriptTag = () => {
  const boundFn = String(setColorsByTheme)
    .replace("🔑", COLOR_MODE_KEY)
    .replace("🌙", darkTheme.className)

  let calledFunction = `(${boundFn})()`
  // calledFunction = Terser.minify(calledFunction).code

  return <script dangerouslySetInnerHTML={{ __html: calledFunction }} />
}

const FallbackStyles = () => {
  return (
    <style
      id="stitches"
      dangerouslySetInnerHTML={{
        __html: getCssText(),
      }}
    />
  )
}

export { MagicScriptTag, FallbackStyles }

export const ThemeContext = React.createContext<ThemeContextValue>({
  colorMode: 'light',
  setColorMode: () => { },
});

export const ThemeProvider: React.FC = ({ children }) => {
  const [colorMode, rawSetColorMode] = React.useState<ColorMode>('light');

  React.useEffect(() => {
    const root = document.documentElement;
    const initialColorMode = root.classList.contains(themes['dark'])
      ? 'dark'
      : 'light';
    rawSetColorMode(initialColorMode);
  }, []);

  const contextValue: ThemeContextValue = React.useMemo(() => {
    function setColorMode(newValue: ColorMode): void {
      document.documentElement.classList.remove(themes[colorMode]);
      document.documentElement.classList.add(themes[newValue]);
      localStorage.setItem(COLOR_MODE_KEY, newValue);
      rawSetColorMode(newValue);
    }

    return {
      colorMode,
      setColorMode,
    };
  }, [colorMode, rawSetColorMode]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};