import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "@fontsource/noto-sans-jp"

import { ThemeProvider } from "@/themes/ThemeProvider"
import GlobalStyles from "./styles/GlobalStyles"

export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <ThemeProvider>
    {GlobalStyles()}
    {element}
  </ThemeProvider>
)
